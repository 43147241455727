<template>
  <div>
    <div
      class="rounded align-center p-3 flex justify-between"
      style="background-color: #dbe2f5"
    >
      <h2 class="text-lg font-bold text-primary text-uppercase">
        Online Class <small class="text-gray-500">( 12 Upcoming Class )</small>
      </h2>
      <div class="flex align-center">
        <div class="bg-periwinkle-gray rounded p-2 ml-2">
          <md-icon class="m-0">filter_alt</md-icon>
        </div>
      </div>
    </div>
    <Table :items="stOnlineClass">
        <template slot="row" slot-scope="{ item }" md-selectable="single">
          <md-table-cell md-numeric>{{ item.id }}</md-table-cell>
          <md-table-cell>{{ item.date }}</md-table-cell>
          <md-table-cell>{{ item.time }}</md-table-cell>
          <md-table-cell class="text-victoria">{{ item.title }}</md-table-cell>
          <md-table-cell>{{ item.medium }}</md-table-cell>
          <md-table-cell>{{ item.zoomLink }}</md-table-cell>
          <md-table-cell class="text-tahiti-gold">{{ item.status }}</md-table-cell>
        </template>
    </Table>
    <div class="flex justify-between align-center my-6">
        <md-button class="bg-victoria text-white text-italic rounded ml-0">Back</md-button>
        <Paginate
        :start="1"
        :end="20"
        :total="100"
        :limit="20"
        />
      </div>
  </div>
</template>

<script>
import { Table, Paginate } from "@/components";
import data from "@/data/online-class/data"
export default {
  components: {
    Table,
    Paginate
  },
  data() {
    return {
      stOnlineClass: data.stOnlineClass
    }
  }
};
</script>

<style></style>
