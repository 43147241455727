const onlineClasses = [];

for (let i = 0; i < 15; i++) {
    onlineClasses.push({
        id: i,
        date: '21 Sep, 2020',
        time: '12:00pm',
        branch: 'Dhanmondi',
        className: 'Intro Class',
        medium: 'Zoom',
        zoomLink: 'https://zoom.com/xtwz-ymqr-254',
        groupName: 'Group A',
        courseName: 'Shapla',
        createdBy: 'Kazi Shahin'
    });
}

const stOnlineClass = [];

for (let i = 0; i < 15; i++) {
    stOnlineClass.push({
        id: i,
        date: '21 Sep, 2020',
        time: '12:00pm',
        title: 'Importance of Listening',
        medium: 'Zoom',
        zoomLink: 'https://zoom.com/xtwz-ymqr-254',
        status: 'upcoming'
    });
}

export default {
    onlineClasses,
    stOnlineClass
}